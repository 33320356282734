import styled from 'styled-components'

export const RootContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 50%;
    min-width: 450px;

    @media screen and (max-width: 460px) {
        min-width: 370px;        
    }

    @media screen and (max-width: 380px) {
        min-width: 320px;     
    }
`;

export const Element = styled.div`
    display: flex;
    // flex-direction: column;
    min-width: 250px;
    width: 100%;
    align-items: center;
`;

export const LabelText = styled.div`
    flex: 1;
    font-size: 18px;
    font-weight: 700;
    color: #688063;
    padding-bottom: 8px;
    text-align: left;
`;

export const ButtonBar = styled.div`
    display: flex;
    align-items: center;
`;