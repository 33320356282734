import {useState} from 'react';
import {
    RootContainer,
    Header,
    Image,
    HeaderText,
    LogoText,
    HeroText,
    Tagline
} from './Start.styles';
import { Button } from '@mui/material';
import { HorizontalSpace, VerticalSpace } from '../Space/Space';

const Start = ({ onSubmit }) => {
    return (
        <RootContainer>
            <Header>
                <Image src="./lb_new_logo_withoutb.png" />
                <VerticalSpace count={2} />
                <HeaderText>X</HeaderText>
                <VerticalSpace count={0} />
                <LogoText><a href = "https://bit.ly/3YsxfhU" target = "_blank">SQUARE FARE</a></LogoText>
            </Header>
            <VerticalSpace count={7} />
            <HeroText>WELCOME!</HeroText>
            <VerticalSpace count={5} />
            <Tagline>Get the right food to<br/>support your goals</Tagline>
            <VerticalSpace count={5} />
            <Button variant="contained" id="start-here-button" onClick={() => onSubmit({})} >Start here</Button>
        </RootContainer>
    )
}

export default Start;