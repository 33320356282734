import {useState, useEffect} from 'react';
import { ThemeProvider } from '@mui/system';
import { useTransition, animated } from 'react-spring';
import validate from 'validate.js';
import theme from './theme';
import {
  Start,
  Biometrics,
  Workout,
  Mindset,
  Result,
  Loading,
  ResultSplashScreen,
} from './components';
import { getDeviceInfo } from './helper';
import moment from 'moment';

import './App.css';
import axios from 'axios';

function App() {
  const [errors, setErrors] = useState({})
  const [surveyData, setSurveyData] = useState({})
  const [stage, setStage] = useState(1);
  const [showEmailPopup, setShowEmailPopup] = useState(false);
  const [results, setResults] = useState([]);
  const [nutritionData, setNutritionData] = useState({});
  const stageTransitions = useTransition(stage, {
    from: {
      display: 'flex',
      alignItems: 'center',
      position: 'absolute',
      top: '100%',
      justifyContent: 'center',
      height: '100vh'
    },
    enter: {
      position: 'absolute',
      height: '100vh',
      top: '0',
      justifyContent: 'center',
      opacity: 1,
    },
    leave: {
      position: 'absolute',
      height: '100vh',
      bottom: '100%',
      justifyContent: 'center',
      opacity: 0,
      transformOrigin: 'right center'
    }
  })

  const onSubmit = async () => {
    try {
      // console.log("REQ BODY", surveyData)
      const deviceInfo = getDeviceInfo();
      
      const {data} = await axios.post('https://squarefare-api.triadkube.com/', {...surveyData, deviceInfo, createdOn: moment.utc()});
      // const {data} = await axios.post('http://localhost:4000/', {...surveyData, deviceInfo, createdOn: moment.utc()});
      // console.log("API RESPONSE DATA", data);

      setShowEmailPopup(data.showEmailPopup);
      setResults(data.result)
      setNutritionData(data.nutrition)
      setTimeout(() => setStage(5), 4000)
    } catch (error) {
      console.log("ERROR", error)
    }
  }

  const onNextPress = (currentScreenData, contraints) => {
    const errors = validate(currentScreenData, contraints)

    if(errors){
      setErrors(errors)
      return false;
    }

    setSurveyData(prev => ({...prev, ...currentScreenData}))
    setErrors({})
    setStage(prev => prev+1)
  }

  const onBackPress = () => {
    setStage(prev => prev-1)
  }

  useEffect(() => {
    if(stage === 4) {
      onSubmit()
    }
    // console.log({stage});
  }, [stage])

  return (
    <ThemeProvider theme={theme} >
        <div className="App">
          {
            stageTransitions((styles, currentStage) => {
              switch(currentStage) {
                case 1: {
                  return (
                    <animated.div style={styles} >
                      <Start onSubmit={onNextPress} errors={errors} />
                    </animated.div>
                  )
                }

                case 2: {
                  return (
                    <animated.div style={styles} >
                      <Biometrics onSubmit={onNextPress} onBack={onBackPress} errors={errors} />
                    </animated.div>
                  )
                }

                case 3: {
                  return (
                    <animated.div style={styles} >
                      <Workout onSubmit={onNextPress} onBack={onBackPress} errors={errors} />
                    </animated.div>
                  )
                }

                // case 4: {
                //   return (
                //     <animated.div style={styles} >
                //       <Mindset onSubmit={onNextPress} onBack={onBackPress} errors={errors} />
                //     </animated.div>
                //   )
                // }

                // case 4: {
                //   return (
                //     <animated.div style={styles} >
                //       <Loading />
                //     </animated.div>
                //   )
                // }

                case 4: {
                  return (
                    <animated.div style={styles} >
                      <ResultSplashScreen result={results} surveyData={surveyData} nutritionData={nutritionData} setStage={setStage} />
                    </animated.div>
                  )
                }

                case 5: {
                  return (
                    <animated.div style={styles} >
                      <Result result={results} surveyData={surveyData} showEmailPopup={showEmailPopup} />
                    </animated.div>
                  )
                }
              }
            })
          }
        </div>
      </ThemeProvider>
  );
}

export default App;
