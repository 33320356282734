export default {
    height: {
        presence: {
            allowEmpty: false,
            message: "^Ah! You can't leave this field empty."
        }
    },
    weight: {
        presence: {
            allowEmpty: false,
            message: "^Ah! You can't leave this field empty."
        }
    },
    // targetWeight: {
    //     presence: {
    //         allowEmpty: false,
    //         message: "^Ah! You can't leave this field empty."
    //     }
    // },
    age: {
        presence: {
            allowEmpty: false,
            message: "^Ah! You can't leave this field empty."
        }
    },
    gender: {
        presence: {
            allowEmpty: false,
            message: "^Ah! You can't leave this field empty."
        }
    }
}