export const heightDropdownValues = [
    {
        label: `4' 5"`,
        value: 53
    },
    {
        label: `4' 6"`,
        value: 54
    },
    {
        label: `4' 7"`,
        value: 55
    },
    {
        label: `4' 8"`,
        value: 56
    },
    {
        label: `4' 9"`,
        value: 57
    },
    {
        label: `4' 10"`,
        value: 58
    },
    {
        label: `4' 11"`,
        value: 59
    },
    {
        label: `5' 0"`,
        value: 60
    },
    {
        label: `5' 1"`,
        value: 61
    },
    {
        label: `5' 2"`,
        value: 62
    },
    {
        label: `5' 3"`,
        value: 63
    },
    {
        label: `5' 4"`,
        value: 64
    },
    {
        label: `5' 5"`,
        value: 65
    },
    {
        label: `5' 6"`,
        value: 66
    },
    {
        label: `5' 7"`,
        value: 67
    },
    {
        label: `5' 8"`,
        value: 68
    },
    {
        label: `5' 9"`,
        value: 69
    },
    {
        label: `5' 10"`,
        value: 70
    },
    {
        label: `5' 11"`,
        value: 71
    },
    {
        label: `6' 0"`,
        value: 72
    },
    {
        label: `6' 1"`,
        value: 73
    },
    {
        label: `6' 2"`,
        value: 74
    },
    {
        label: `6' 3"`,
        value: 75
    },
    {
        label: `6' 4"`,
        value: 76
    },
    {
        label: `6' 5"`,
        value: 77
    },
    {
        label: `6' 6"`,
        value: 78
    },
    {
        label: `6' 7"`,
        value: 79
    },
    {
        label: `6' 8"`,
        value: 80
    },
    {
        label: `6' 9"`,
        value: 81
    },
    {
        label: `6' 10"`,
        value: 82
    },
    {
        label: `6' 11"`,
        value: 83
    },
    {
        label: `7' 0"`,
        value: 84
    },
]

export const weightDropdown = (start, end) => {
    const dropdownItems = []

    for (var i = start; i <= end; i++) {
        dropdownItems.push({label: `${i} lbs`, value: i})
    }

    return dropdownItems;
}

export const ageDropdown = (start, end) => {
    const dropdownItems = []

    for (var i = start; i <= end; i++) {
        dropdownItems.push({label: `${i}`, value: i})
    }

    return dropdownItems;
}