import { createTheme } from "@mui/material";

const theme = createTheme({
    components: {
        MuiButton: {
            defaultProps: {
                disableRipple: true, // No more ripple, on the whole application 💣!
            },
            styleOverrides: {
                root: {
                    backgroundColor: '#688063',
                    fontWeight: 'bold',
                    color: '#fff',
                    '&:hover': {
                        backgroundColor: '#43523f'
                    }
                },
            }
        },
        MuiInputLabel: {
            styleOverrides: {
              root: {
                color: "#7A7A7A"
              }
            }
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    color: '#7A7A7A !important'
                }
            }
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    borderColor: "#7A7A7A"
                }
            }
        },
        MuiInput: {
            styleOverrides: {
                root: {
                    color: "#7A7A7A"
                },
                underline: {
                    '&:before': {
                        borderBottom: `1px solid #ccc`
                    },

                    '&:after': {
                        borderBottom: `2px solid #FFF`
                    }
                }
            }
        },
        MuiSelect: {
            styleOverrides: {
                standard: {
                    borderColor: "#7A7A7A",
                    width: '100%'
                },
                iconStandard: {
                    color: "#7A7A7A"
                },
                nativeInput: {
                    color: "#7A7A7A"
                },
                select: {
                    color: "#7A7A7A"
                }
            },
            focused: {
                borderColor: "#7A7A7A"
            }
        },
        MuiFormControl: {
            styleOverrides: {
                root: {
                    margin: '0px',
                    textAlign: 'left'
                }
            }
        },
        MuiFormControlLabel: {
            styleOverrides: {
                label: {
                    color: '#688063'
                }
            }
        },
        MuiListItemText: {
            styleOverrides: {
                primary: {
                    color: '#7A7A7A',
                    fontSize: '18px',
                    fontWeight: 500
                    
                }
            }
        },
        MuiTypography: {
            styleOverrides: {
                caption: {
                    position: 'absolute',
                    width: '110%',
                    top: '100%',
                    textAlign: 'left',
                    color: '#FFF',
                    // textShadow: '0px 1px 2px #fff',
                    fontWeight: 'bold',
                    padding: '5px',
                    backgroundColor: '#C33149'
                },
                h5: {
                    color: "#688063",
                    textAlign: 'center',
                },
                subtitle1: {
                    color: "#688063",
                    textAlign: 'center',
                }
            }
        }
    },
});

export default theme;