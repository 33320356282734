import styled from 'styled-components'

export const RootContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 50%;
    min-width: 400px;
`;

export const Element = styled.div`
    position: relative;
    display: flex;
    min-width: 250px;
    width: 100%;
    align-items: flex-end;
    margin-bottom: 20px;

    & {
        @media screen and (max-width: 400px) {
            max-width: 300px;
            align-items: center;
        }
    }
`;

export const LabelText = styled.div`
    flex: 1;
    font-size: 18px;
    font-weight: 700;
    color: #688063;
    padding-bottom: 8px;
    text-align: left;

    & {
        @media screen and (max-width: 400px) {
            flex: 1;
        }
    }
`;

export const ButtonBar = styled.div`
    display: flex;
    align-items: center;
`;