export const workoutDropdownValues = [
    {
        label: `I’m not working out today`,
        value: 1
    },
    {
        label: `A low intensity workout (walking)`,
        value: 2
    },
    {
        label: `A medium intensity workout (running)`,
        value: 3
    },
    {
        label: `A high intensity workout (boxing)`,
        value: 4
    }
]

export const mindsetList = [
    {
        label: 'Standard macros',
        value: 1
    },
    {
        label: 'Low carb / High protein',
        value: 3
    },
    {
        label: 'Keto',
        value: 4
    },
    {
        label: 'Mediterranean',
        value: 5
    },
]