import styled from 'styled-components'

export const RootContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 75vw;
    overflow-y: scroll;
`;

export const TitleText = styled.div`
    font-size: 30px;
    color: #688063;
    font-weight: 700;
    max-width: 550px;

    @media screen and (max-width: 375px) {
        width: 350px !important;
        font-size: 24px;
    }

    @media screen and (max-width: 320px) {
        width: 280px !important;
        font-size: 24px;
    }

`;

export const FoodImage = styled.img`
    width: 200px !important;
    height: auto;

    @media screen and (max-width: 365px) {
        width: 200px !important;
    }
`;

export const DishName = styled.div`
    font-size: 20px;
    max-width: 550px;
    font-weight: 600;
    text-align: center;
    color: #688063;

    span {
        color: #7a7a7a;
        font-weight: 500;
        font-size: 18px;
    }

    @media screen and (max-width: 545px) {
        width: 500px !important;
        font-size: 18px;

        span {
            font-size: 16px;
        }
    }    

    @media screen and (max-width: 500px) {
        width: 450px !important;
        font-size: 18px;

        span {
            font-size: 16px;
        }
    }

    @media screen and (max-width: 450px) {
        width: 400px !important;
        font-size: 18px;

        span {
            font-size: 16px;
        }
    }

    @media screen and (max-width: 400px) {
        width: 320px !important;
        font-size: 18px;

        span {
            font-size: 14px;
        }
    }

    @media screen and (max-width: 320px) {
        width: 280px !important;
        font-size: 18px;

        span {
            font-size: 14px;
        }
    }
`;

export const NutritionalInfo = styled.div`
    font-size: 18px;
    max-width: 550px;
    text-align: center;
    color: #7A7A7A;
    font-weight: 500;

    @media screen and (max-width: 545px) {
        width: 500px !important;
        font-size: 16px;
    }    

    @media screen and (max-width: 500px) {
        width: 450px !important;
        font-size: 16px;
    }

    @media screen and (max-width: 450px) {
        width: 400px !important;
        font-size: 16px;
    }

    @media screen and (max-width: 400px) {
        width: 320px !important;
        font-size: 14px;
    }

    @media screen and (max-width: 320px) {
        width: 280px !important;
        font-size: 14px;
    }
`;

export const PreferenceText = styled(NutritionalInfo)`
    font-weight: 500;

    @media screen and (max-width: 545px) {
        width: auto !important;
        font-size: 16px;
    }    

    @media screen and (max-width: 500px) {
        width: auto !important;
        font-size: 16px;
    }

    @media screen and (max-width: 450px) {
        width: auto !important;
        font-size: 16px;
    }

    @media screen and (max-width: 350px) {
        width: auto !important;
        font-size: 14px;
    }  

    @media screen and (max-width: 320px) {
        width: auto !important;
        font-size: 14px;
    }
`;

export const EmailText = styled(NutritionalInfo)`
    font-size: 24px;
    text-align: left;
    max-width: 450px;
`;

export const SlideCard = styled.div.attrs({className: "swiper-slide"})`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const FootNote = styled.div`
    padding: 10px;
    font-size: 14px;
    color: #7A7A7A;
    max-width: 400px;
    a {
        color: #7A7A7A;
    }

    @media screen and (max-width: 375px) {
        max-width: 320px;
    }

    @media screen and (max-width: 320px) {
        max-width: 300px;
    }
`;

export const Element = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-around;
`;

export const SubmitButtonContainer = styled.div`
    width: 50%;
    display: flex;
    justify-content: center;
`;

export const FoodFeatures = styled.div`
    display: flex;
    align-items: center;
    margin: 0px 5px;

    @media screen and (max-width: 320px) {
        width: 300px !important;
        font-size: 20px;
        justify-content: center;
    }
`;

export const FoodFeaturesContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    max-width: 80%;
    justify-content: center;

    @media screen and (max-width: 665px) {
        width: 490px !important;
        justify-content: center;
    }    

    @media screen and (max-width: 490px) {
        width: 360px !important;
        justify-content: center;
    }
`;

export const FadeScreen = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, .4);
    z-index: 1;

    @media only screen and (max-width: 425px) {
        & .swiper-slide {
            position: absolute;
            bottom: 0;
        }
    }
`;

export const CloseBtnContainer = styled.div`
    position: absolute;
    right: 0;
    top: 0;
    padding: 0.8rem;
    cursor: pointer;
    z-index: 1;
`;