import {useState} from 'react';
import {
    RootContainer,
    Element,
    LabelText,
    ButtonBar
} from './Workout.styles';
import {HorizontalSpace, VerticalSpace, PoweredByText} from '../Space/Space'
import {
    Select,
    MenuItem,
    InputLabel,
    FormControl,
    FormControlLabel,
    FormLabel,
    RadioGroup,
    Radio,
    Button
} from '@mui/material';
import {
    workoutDropdownValues,
    mindsetList
} from './values'

const Biometrics = ({onSubmit, onBack}) => {
    const [workout, setWorkout] = useState(1)
    const [macros, setMacros] = useState([1])

    return (
        <RootContainer>
            <Element>
                <LabelText>Your workout: </LabelText>
                <FormControl size='large' variant="standard" sx={{ m: 1, minWidth: 200, flex: 2 }}>
                    <Select label="workout" labelId="demo-simple-select-standard-label1" value={workout} onChange={(event) => setWorkout(event.target.value)} >
                        {workoutDropdownValues.map(item => <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>)}
                    </Select>
                </FormControl>
            </Element>
            <VerticalSpace count={5} />
            <Element>
                <LabelText>Your macros: </LabelText>
                <FormControl size='large' variant="standard" sx={{ m: 1, minWidth: 200, flex: 2 }}>
                    <Select label="macros" labelId="demo-simple-select-standard-label1" value={macros[0]} onChange={(event) => setMacros([event.target.value])} >
                        {mindsetList.map(item => <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>)}
                    </Select>
                </FormControl>
            </Element>            
            <VerticalSpace count={5} />
            <ButtonBar>
                <Button variant="contained" id="page-2-back-button" onClick={onBack} >Back</Button>
                <HorizontalSpace count={3} />
                <Button variant="contained" id="page-2-next-button" onClick={() => onSubmit({workout, diet: macros})} >Next</Button>
            </ButtonBar>
            <VerticalSpace count={5} />
            <PoweredByText>Powered by <a href = "https://bit.ly/3YsxfhU" target = "_blank">Square Fare</a></PoweredByText>
        </RootContainer>
    )
}

export default Biometrics;