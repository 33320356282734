import styled from 'styled-components';

export const RootContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const Header = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & {
        @media screen and (max-width: 400px) {
            flex-direction: column;
            height: 250px;
            justify-content: space-around;
        }
    }
`;

// https://ucarecdn.com/f69146e3-bb8f-45d2-b8ae-02d7d4e3f354/-/preview/-/quality/smart/-/format/auto/
export const Image = styled.img`
    width: 280px;
    height: auto;
`;

export const HeaderText = styled.span`
    color: #000;
    font-size: 30px;
    font-weight: 600;
    // letter-spacing: 5px;
    a {
        color: black;
        text-decoration: none;
        font-family: 'Aktiv Grotesk Trial', sans-serif;
    };
`;

export const LogoText = styled.span`
    color: #000;
    font-size: 50px;
    // letter-spacing: 5px;
    a {
        color: black;
        text-decoration: none;
        font-family: 'DIN Condensed', sans-serif;
    };
`;

export const HeroText = styled.div`
    font-size: 40px;
    color: #688063;
    font-weight: 700;
    letter-spacing: 3px;
`;

export const Tagline = styled.div`
    font-size: 20px;
    color: #7A7A7A;
    font-weight: 500;
`;