import styled from 'styled-components'

export const RootContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 75vw;
    overflow-y: scroll;
`;

export const TitleText = styled.div`
    font-size: 30px;
    color: #688063;
    font-weight: 700;
    max-width: 550px;
    padding-bottom: 10px;
    border-bottom: 2px solid #688063;

    @media screen and (max-width: 320px) {
        width: 300px !important;
        font-size: 24px;
    }

`;

export const DishName = styled.div`
    font-size: 24px;
    max-width: 550px;
    text-align: center;
    color: #7A7A7A;

    @media screen and (max-width: 545px) {
        width: 500px !important;
        font-size: 18px;
    }     

    @media screen and (max-width: 500px) {
        width: 450px !important;
        font-size: 18px;
    }

    @media screen and (max-width: 450px) {
        width: 400px !important;
        font-size: 18px;
    }

    @media screen and (max-width: 400px) {
        width: 320px !important;
        font-size: 18px;
    }

    @media screen and (max-width: 320px) {
        width: 300px !important;
        font-size: 18px;
    }
`;

export const NutritionalInfo = styled.div`
    font-size: 18px;
    max-width: 350px;
    text-align: center;
    color: #7A7A7A;

    @media screen and (max-width: 320px) {
        width: 300px !important;
        font-size: 14px;
    }
`;