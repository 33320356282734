import styled from 'styled-components';

export const HorizontalSpace = styled.div`
    width: ${({count}) => count*5}px;
`;

export const VerticalSpace = styled.div`
    height: ${({count}) => count*5}px;
`;

export const PoweredByText = styled.div`
    font-size: 1rem;
    margin: 20px 0px;
    color: #688063;
    a {
        color: #688063;
    };
`;

export const PopupPoweredByText = styled(PoweredByText)`
    font-size: 0.8rem;
    margin: 10px 0;
`;