import UAParser from 'ua-parser-js';


export function getDeviceInfo() {
    const parser = new UAParser(window.navigator.userAgent);
    const deviceInfo = {
      ...parser.getResult(),
      connection: window?.navigator?.connection, 
      mobile: window?.navigator?.userAgentData?.mobile
    }
    return deviceInfo;
}



export function getRandomIndices(min, max, count) {
  const indices = [];
  count = (max - min) < count ? max - min : count
  while(indices.length < count) {
    const randInt = Math.floor(Math.random() * (max - min)) + min;
    if (!indices.includes(randInt)) {
      indices.push(randInt);
    }
  }

  return indices;
}