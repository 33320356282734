import {useState, useEffect} from 'react';
import {
    RootContainer,
    TitleText,
    DishName,
    NutritionalInfo,
} from './ResultSplashScreen.styles';
import {VerticalSpace, PoweredByText} from '../Space/Space';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import BarLoader from "react-spinners/BarLoader";

function toTitleCase(str) {
    return str.replace(
      /\w\S*/g,
      function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      }
    );
  }

const ResultSplashScreen = ({result, surveyData, nutritionData, setStage}) => {
    const [preferences, setPreferences] = useState([])

    const getPreferences = () => {
        const values = surveyData?.diet?.map(preference => {
            switch(preference) {
                case 0:
                    return "Standard macros"
                
                // case 1:
                //     return "Weight Loss"
                
                // case 2:
                //     return "Gain Muscle Mass"

                case 3:
                    return "Low carb / High protein meal"

                case 4: 
                    return "Keto meal"

                case 5:
                    return "Mediterranean meal"
            }
        })

        setPreferences(values)
    }

    const getMacro = (macro) => {
        switch(macro) {
            case 'balanced':
                return 'Standard'
            case 'lowCarb':
                return 'Low carb / High protein'
            case 'keto':
                return 'Keto'
            case 'mediterranean':
                return 'Mediterranean'
        }
    }

    // console.log("Preferences", nutritionData?)

    return (
        <RootContainer>
            <TitleText>Your selection is almost ready!</TitleText>
            <VerticalSpace count={5} />
            <BarLoader color="#7A7A7A" />
            <VerticalSpace count={5} />
            {
                nutritionData?.calorie
                ?
                <>
                    <DishName>Target Calories: {nutritionData?.calorie-nutritionData?.calorie*0.1} - {nutritionData?.calorie+nutritionData?.calorie*0.1} kcals</DishName>
                    <VerticalSpace count={7} />
                    <DishName>Target Macros: {getMacro(nutritionData?.dietDetails?.dietType)}</DishName>
                    <DishName>({nutritionData?.dietDetails?.protein}% protein, {nutritionData?.dietDetails?.carbs}% carb, {nutritionData?.dietDetails?.fat}% fat)</DishName>
                    <VerticalSpace count={2} />
                    <NutritionalInfo>Approx: {nutritionData?.protein}g protein, {nutritionData?.carbs}g carb, {nutritionData?.fat}g fat</NutritionalInfo>
                </>
                :
                null
            }
            <VerticalSpace count={5} />
            <PoweredByText>Powered by <a href = "https://bit.ly/3YsxfhU" target = "_blank">Square Fare</a></PoweredByText>            
        </RootContainer>
    )
}

export default ResultSplashScreen;
