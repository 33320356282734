import {useState} from 'react';
import {
    RootContainer,
    Element,
    LabelText,
    ButtonBar
} from './Biometrics.styles';
import {HorizontalSpace, VerticalSpace, PoweredByText} from '../Space/Space'
import {
    Select,
    MenuItem,
    InputLabel,
    FormControl,
    FormControlLabel,
    Typography,
    RadioGroup,
    Radio,
    Button
} from '@mui/material';
import {
    heightDropdownValues,
    weightDropdown,
    ageDropdown
} from './values';
import contraints from './validations';

const Biometrics = ({onSubmit, onBack, errors}) => {
    const [height, setHeight] = useState('')
    const [weight, setWeight] = useState('')
    const [targetWeight, setTargetWeight] = useState('')
    const [age, setAge] = useState('')
    const [gender, setGender] = useState('')

    return (
        <RootContainer>
            <Element>
                <LabelText>Your height: </LabelText>
                <FormControl size='large' variant="standard" sx={{ m: 1, minWidth: 200 }}>
                    <InputLabel id="demo-simple-select-label1" sx={{'&.Mui-focused': {color: '#7A7A7A'}}} >Your height</InputLabel>
                    <Select label="Your height" labelId="demo-simple-select-standard-label1" value={height} onChange={(event) => setHeight(event.target.value)} >
                        {heightDropdownValues.map(item => <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>)}
                    </Select>
                    {
                        errors.height && <Typography variant="caption" >{errors.height}</Typography>
                    }
                </FormControl>
            </Element>
            <Element>
                <LabelText>Your weight: </LabelText>
                <FormControl size='large' variant="standard" sx={{ m: 1, minWidth: 200 }}>
                    <InputLabel id="demo-simple-select-label1" sx={{'&.Mui-focused': {color: '#7A7A7A'}}} >Your weight</InputLabel>
                    <Select label="Your weight" labelId="demo-simple-select-standard-label1" value={weight} onChange={(event) => setWeight(event.target.value)} >
                        {weightDropdown(100, 400).map(item => <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>)}
                    </Select>
                    {
                        errors.weight && <Typography variant="caption" >{errors.weight}</Typography>
                    }
                </FormControl>
            </Element>
            <Element>
                <LabelText>Target weight: </LabelText>
                <FormControl size='large' variant="standard" sx={{ m: 1, minWidth: 200 }}>
                    <InputLabel id="demo-simple-select-label1" sx={{'&.Mui-focused': {color: '#7A7A7A'}}} >Target weight</InputLabel>
                    <Select label="Target weight" labelId="demo-simple-select-standard-label1" value={targetWeight} onChange={(event) => setTargetWeight(event.target.value)} >
                        {weightDropdown(100, 400).map(item => <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>)}
                    </Select>
                    {
                        errors.targetWeight && <Typography variant="caption" >{errors.targetWeight}</Typography>
                    }
                </FormControl>
            </Element>
            <Element>
                <LabelText>Your age: </LabelText>
                <FormControl size='large' variant="standard" sx={{ m: 1, minWidth: 200 }}>
                    <InputLabel id="demo-simple-select-label1" sx={{'&.Mui-focused': {color: '#7A7A7A'}}} >Your age</InputLabel>
                    <Select label="Your age" labelId="demo-simple-select-standard-label1" value={age} onChange={(event) => setAge(event.target.value)} >
                        {ageDropdown(18, 99).map(item => <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>)}
                    </Select>
                    {
                        errors.age && <Typography variant="caption" >{errors.age}</Typography>
                    }
                </FormControl>
            </Element>            
            <VerticalSpace count={3} />
            <Element>
                <LabelText>Sex: </LabelText>
                <FormControl sx={{ m: 1, minWidth: 200 }}>
                    <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={gender}
                        onChange={(event) => setGender(event.target.value)}
                    >
                        <FormControlLabel 
                            value="male"
                            control={
                                <Radio sx={{color: '#688063','&.Mui-checked': {color: '#7A7A7A'}}} />
                            }
                            label="Male" 
                        />
                        <FormControlLabel 
                            value="female"
                            control={
                                <Radio sx={{color: '#688063','&.Mui-checked': {color: '#7A7A7A'}}} />
                            }
                            label="Female" 
                        />
                    </RadioGroup>
                    {
                        errors.gender && <Typography variant="caption" >{errors.gender}</Typography>
                    }
                </FormControl>
            </Element>
            <VerticalSpace count={5} />
            <ButtonBar>
                <Button variant="contained" id="page-1-back-button" onClick={onBack} >Back</Button>
                <HorizontalSpace count={3} />
                <Button variant="contained" id="page-1-next-button" onClick={() => onSubmit({height, weight, targetWeight, age, gender}, contraints)} >Next</Button>
            </ButtonBar>
            <VerticalSpace count={5} />
            <PoweredByText>Powered by <a href = "https://bit.ly/3YsxfhU" target = "_blank">Square Fare</a></PoweredByText>
        </RootContainer>
    )
}

export default Biometrics;